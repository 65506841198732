<template>
    <div class="row">
        <div class="col-10 mb-0 col-sm-9 col-lg-10">
          <p class="groupMobile" style="margin-left: 9px;">Default message settings</p>
        </div>
        <div class="col-2 col-sm-3 col-lg-2">
          <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
        </div>
      </div>
      <div class="hr-border"></div>
      <div class="mobileStyleDoctor">
    <div class="row mt-3">
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" checked disabled type="checkbox" name="mn3" id="mn3" value="mn3">
                <label class="form-check-label" for="mn3" >Send notifications to my primary mail ID and mobile number</label>
            </div>
        </div>
    </div>
</div>
</template>
<script>

export default {
  components: {
   
  },
  data() {
  return {
  }},

created: async function () {

},
methods:{
    GoSetting() {
        window.scrollTo(0, 0);
        this.$router.push(`/clinicianothers/mobile-settings`);
      },
}
}
</script>
<style>
    .groupMobile {
      margin-left: 20px;
      /* margin-bottom: 10px; */
      margin-top: 1rem;
      color: #000;
      font-weight: 550;
      font-size: 21px;
    }
    @media screen and (max-width:991px) {
    .mobileStyleDoctor {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 6rem;
    }}
    </style>